import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {StorageService} from '../../shared/services/storage.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertService} from '../../shared/services/alert.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from '../../shared/services/auth.service';
import {GlobalService} from '../../shared/services/global.service';
import {StudentService} from '../../shared/services/student.service';
import {CourseService} from '../../shared/services/course.service';
import {CenterService} from '../../shared/services/center.service';

@Component({
  selector: 'ps-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  signup: boolean;
  centerLoginOtpForm: FormGroup;
  contactVerifyForm: FormGroup;
  updatePasswordForm: FormGroup;
  loginForm: FormGroup;
  submitting: any = false;
  submittingOtp: any = false;
  submittingPassword: any = false;
  submitted: any = false;
  submittedLogin: any = false;
  submittedOtp: any = false;
  submittedContactVerify: any = false;
  timerSixtySeconds: number = 60;
  interval: any;
  @ViewChild('content') templateRef: TemplateRef<any>;
  responseRegister: any;
  isBusy: any = false;
  modalType: any;
  inputTypePassword: any = 'password';
  inputTypeConfirmPassword: any = 'password';
  decodedResponseOtp: any;

  constructor(private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private courseService: CourseService,
              public alertService: AlertService,
              private studentService: StudentService,
              public modalService: NgbModal,
              private authService: AuthService,
              private globalService: GlobalService,
              private centerService: CenterService,
              private router: Router) {
    const url = this.route.snapshot.url;
    if (url[0].path === 'simple-register') {
      this.signup = true;
    }
    this.checkLogin();
  }

  ngOnInit(): void {
    this.centerLoginOtpForm = this.formBuilder.group({
      otp: ['', [Validators.required]]
    });

    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });

    this.contactVerifyForm = this.formBuilder.group({
      'contact': ['', Validators.compose([Validators.required, Validators.pattern('^[5-9]{1}[0-9]{9}$')])]
    });

    this.updatePasswordForm = this.formBuilder.group({
      contact: [{'value': '', disabled: true}],
      otp: ['', [Validators.required]],
      password: ['', [Validators.required]],
      confirm_password: ['', [Validators.required]]
    });
  }

  checkLogin() {
    if (StorageService.getItem('accessToken') && StorageService.getItem('self')) {
      this.globalService.setAccessToken(StorageService.getItem('accessToken'));
      this.globalService.setSelf(StorageService.getItem('self'));
      this.reloadSelf();
    } else {
      this.router.navigateByUrl('auth/login');
    }
  }

  reloadSelf() {
    this.authService.self({all: true}).subscribe((data) => {
      this.globalService.setSelf({
        'name': data.name,
        'user_id': data.id,
        'username': data.username,
        'email': data.email,
        'contact': data.contact,
        'belongs_to': data.belongs_to
      });
      this.router.navigateByUrl('general/home');
    }, e => {
      StorageService.clearAll();
      this.router.navigateByUrl('auth/login');
    });
  }

  resendOTP() {
    this.submitting = true;
    this.authService.login(this.loginForm.getRawValue()).subscribe(data => {
      if (data && data.unique_id) {
        this.responseRegister = data;
        this.resendOTPTimer();
      }
    }, error => {
      this.isBusy = false;
      this.submittedLogin = false;
    });
  }

  resendOTPTimer() {
    this.timerSixtySeconds = 60;
    this.interval = setInterval(() => {
      if (this.timerSixtySeconds > 0) {
        this.timerSixtySeconds--;
      } else {
        this.interval = undefined;
      }
    }, 1000);
  }

  submitOtp() {
    this.submittedOtp = true;
    if (!this.centerLoginOtpForm.valid) {
      return;
    }
    if (this.submittedOtp) {
      this.centerService.verifyOTP({
        'unique_id': this.responseRegister.unique_id,
        'otp': this.centerLoginOtpForm.value.otp,
        'decoded_otp': this.responseRegister.decoded_otp
      }).subscribe(data => {
        if (data && data['user_id']) {
          this.modalService.dismissAll('');
          this.globalService.setAccessToken(data.token);
          this.globalService.setSelf({
            'name': data.name,
            'email': data.email,
            'contact': data.contact,
            'user_id': data.user_id,
            'username': data.username,
            'belongs_to': data.belongs_to
          });
          this.router.navigateByUrl('general/home');
        }
        this.submittingOtp = false;
      }, error => {
        this.submittingOtp = false;
      });
    }
  }

  checkLoggedInStatus() {
    const accessToken = StorageService.getItem('accessToken');
    return accessToken && accessToken !== 'null';
  }

  setSelfData() {
    this.authService.self({all: true}).subscribe((data) => {
      this.isBusy = false;
      this.globalService.setSelf({
        'name': data.name,
        'user_id': data.id,
        'username': data.username,
        'email': data.email,
        'contact': data.contact,
        'belongs_to': data.belongs_to
      });
      return;
    }, e => {
      this.isBusy = false;
      StorageService.clearAll();
    });
  }

  signIn() {
    this.submittedLogin = true;
    if (!this.loginForm.valid) {
      return;
    }
    if (this.submittedLogin) {
      if (this.checkLoggedInStatus()) {
        this.setSelfData();
        this.router.navigateByUrl('general/home');
      } else {
        this.authService.login(this.loginForm.getRawValue()).subscribe(data => {
          if (data && data.unique_id) {
            this.responseRegister = data;
            this.modalType = 'LOGIN_OTP';
            this.modalService.open(this.templateRef);
            this.resendOTPTimer();
          }
        }, error => {
          this.isBusy = false;
          this.submittedLogin = false;
        });
      }
    }
  }

  openForgotPasswordModal(content) {
    this.modalType = 'FORGOT_PASSWORD';
    this.modalService.open(content);
  }

  verifyContact() {
    this.submittedContactVerify = true;
    if (!this.contactVerifyForm.valid) {
      return;
    }
    if (this.submittedContactVerify) {
      this.authService.verifyContact(this.contactVerifyForm.getRawValue()).subscribe(data => {
        if (data) {
          this.decodedResponseOtp = (data && data['decoded_otp']) ? data['decoded_otp'] : '';
          this.updatePasswordForm.patchValue({
            'contact': this.contactVerifyForm.getRawValue().contact
          });
          this.modalService.dismissAll('');
          this.modalType = 'RESET_PASSWORD';
          this.modalService.open(this.templateRef);
          this.submittedContactVerify = false;
        }
      }, error => {
        this.submittedContactVerify = false;
      });
    }
  }

  resetPassword() {
    this.submitted = true;
    if (!this.updatePasswordForm.valid) {
      return;
    }
    this.submittingPassword = true;
    if (this.updatePasswordForm.value.password === this.updatePasswordForm.value.confirm_password) {
      let params = {
        'password': this.updatePasswordForm.getRawValue().password,
        'contact': this.updatePasswordForm.getRawValue().contact,
        'otp': this.updatePasswordForm.getRawValue().otp,
        'decoded_otp': this.decodedResponseOtp,
      };
      this.authService.resetPassword(params).subscribe(data => {
        this.modalService.dismissAll('');
        this.submittingPassword = false;
      }, error => {
        this.submittingPassword = false;
      });
    } else {
      this.submittingPassword = false;
      this.alertService.showErrors('Password and Confirm Password didn\'t match');
    }
  }
}
