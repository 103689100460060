<!--<div class="authentication-main">
  <ng-template #content let-modal>
    <div id="modal">
      <div class="modal-header font-weight-bold">
        <h4 class="modal-title font-weight-bold" *ngIf="modalType === 'LOGIN_OTP'">Login OTP</h4>
        <h4 class="modal-title font-weight-bold" *ngIf="modalType === 'FORGOT_PASSWORD'">Forgot Password</h4>
        <h4 class="modal-title font-weight-bold" *ngIf="modalType === 'RESET_PASSWORD'">Reset Password</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="modalService.dismissAll('')"
        >
          ×
        </button>
      </div>
      <div class="modal-body" ngbAutoFocus>
        <div class="row" *ngIf="modalType === 'LOGIN_OTP'">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <form class="form" novalidate [formGroup]="centerLoginOtpForm">
                  <div class="row">
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                      <fieldset class="form-group">
                        <input class="form-control" type="number" formControlName="otp"
                               placeholder="Enter OTP">
                        <small *ngIf="submittedOtp && centerLoginOtpForm.get('otp').hasError('required')"
                               class="text-danger">
                          Field is required.
                        </small>
                      </fieldset>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-right">
                <small>Resend OTP in - </small>
                <small class="text-danger mr-1"
                       *ngIf="timerSixtySeconds > 0">{{timerSixtySeconds}} sec.</small>
                <small class="text-primary cursor-pointer"
                       *ngIf="timerSixtySeconds === 0" (click)="resendOTP()">Resend OTP ?
                </small>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="modalType === 'FORGOT_PASSWORD'">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <form class="form" novalidate [formGroup]="contactVerifyForm">
                  <div class="row">
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                      <fieldset class="form-group">
                        <label>Contact No.</label>
                        <input class="form-control" type="number" formControlName="contact"
                               placeholder="Enter Contact">
                        <small *ngIf="submittedContactVerify && contactVerifyForm.get('contact').hasError('required')"
                               class="text-danger">
                          Field is required.
                        </small>
                      </fieldset>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="modalType === 'RESET_PASSWORD'">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <form class="form" novalidate [formGroup]="updatePasswordForm">
                  <div class="row">
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                      <div class="form-group">
                        <label class="col-form-label">Contact</label>
                        <input class="form-control" formControlName="contact"/>
                        <small *ngIf="submitted && updatePasswordForm.get('contact').hasError('required')"
                               class="text-danger">
                          Field is required.
                        </small>
                      </div>
                    </div>
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                      <div class="form-group">
                        <label class="col-form-label d-flex">
                          Password
                          <i (click)="inputTypePassword = 'text'"
                             [ngClass]="{'display-hidden': (inputTypePassword === 'text')}"
                             class="fas fa-eye-slash d-flex align-items-center pl-1 cursor-pointer f_10"></i>
                          <i (click)="inputTypePassword = 'password'"
                             [ngClass]="{'display-hidden': (inputTypePassword === 'password')}"
                             class="fas fa-eye d-flex align-items-center pl-1  cursor-pointer f_10"></i>
                        </label>
                        <input class="form-control" [type]="inputTypePassword" formControlName="password"/>
                        <small *ngIf="submitted && updatePasswordForm.get('password').hasError('required')"
                               class="text-danger">
                          Field is required.
                        </small>
                      </div>
                    </div>
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                      <div class="form-group">
                        <label class="col-form-label d-flex">
                          Confirm Password
                          <i (click)="inputTypeConfirmPassword = 'text'"
                             [ngClass]="{'display-hidden': (inputTypeConfirmPassword === 'text')}"
                             class="fas fa-eye-slash d-flex align-items-center pl-1 cursor-pointer f_10"></i>
                          <i (click)="inputTypeConfirmPassword = 'password'"
                             [ngClass]="{'display-hidden': (inputTypeConfirmPassword === 'password')}"
                             class="fas fa-eye d-flex align-items-center pl-1  cursor-pointer f_10"></i>
                        </label>
                        <input class="form-control" [type]="inputTypeConfirmPassword" formControlName="confirm_password"/>
                        <small *ngIf="submitted && updatePasswordForm.get('confirm_password').hasError('required')"
                               class="text-danger">
                          Field is required.
                        </small>
                      </div>
                    </div>
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                      <div class="form-group">
                        <label class="col-form-label">Verification Code</label>
                        <input class="form-control" formControlName="otp" type="number"/>
                        <small *ngIf="submitted && updatePasswordForm.get('otp').hasError('required')"
                               class="text-danger">
                          Field is required.
                        </small>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-center">
            <button class="btn btn-primary btn-block btn-raised color-white mr-2 users-btn"
                    (click)="submitOtp()"
                    [disabled]="submittingOtp" *ngIf="modalType === 'LOGIN_OTP'">
              <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submittingOtp"></i>Submit
            </button>
            <button class="btn btn-primary btn-block btn-raised color-white mr-2 users-btn"
                    (click)="verifyContact()"
                    [disabled]="submittingOtp" *ngIf="modalType === 'FORGOT_PASSWORD'">
              <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submittingOtp"></i>Verify
            </button>
            <button class="btn btn-primary btn-block btn-raised color-white mr-2 users-btn"
                    (click)="resetPassword()"
                    [disabled]="submittingPassword" *ngIf="modalType === 'RESET_PASSWORD'">
              <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submittingPassword"></i>Reset
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <div class="row">
    <div class="col-md-12">
      <div class="auth-innerright">
        <div class="authentication-box">
          <div class="mt-4">
            <div class="card-header" style="background: transparent; border-bottom: none ; margin-bottom: 20px">
              <div class="main-header-left text-center">
                &lt;!&ndash;<div class="logo-wrapper"><a href="javascript:void(0)" routerLink='/'>
                  <img src="assets/images/logo/logo2.png" alt="University Management System" style="height: 70px"></a>
                </div>&ndash;&gt;
              </div>
            </div>
            <div class="card-body px-0">
              <div class="cont text-center b-light" [ngClass]="{'s&#45;&#45;signup': signup}">
                <div>
                  <form class="theme-form" [formGroup]="loginForm">
                    <h4>Sign In</h4>
                    <h6>Sign In to your account and start exploring</h6>
                    <div class="form-group text-left mt-30px">
                      <label class="col-form-label pt-0">Username</label>
                      <input class="form-control border-radius-0" type="text" placeholder="volmint"
                             formControlName="username"/>
                    </div>
                    <div class="form-group text-left">
                      <label class="col-form-label">Password</label>
                      <input class="form-control border-radius-0" type="password" placeholder="*******"
                             formControlName="password"/>
                    </div>
                    <div class="form-group text-left text-info mt-55px">
                      <div class="row mb-30px">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div class="checkbox p-0 text-left">
                            <input id="checkbox1" type="checkbox">
                            <label for="checkbox1" class="f_12">
                              <small>Remember me</small>
                            </label>
                          </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                          <h6 class="col-form-label f_12 cursor-pointer mt-5px" (click)="openForgotPasswordModal(content)">Forgot Password ?</h6>
                        </div>
                      </div>
                    </div>
                    <div class="form-group form-row mt-3 mb-0">
                      <button class="btn btn-primary btn-block" type="submit" (click)="signIn()">Sign In</button>
                    </div>
                    <div class="social mt-3 d-block d-md-none height-150px">
                    </div>
                  </form>
                </div>
                <div class="sub-cont">
                  <div class="img">
                    <div class="img__text m&#45;&#45;up">
                      <h2>Swami Vivekanand Subharti University</h2>
                    </div>
                    <div class="img__text m&#45;&#45;in">
                      <h2>Already Registered ?</h2>
                      <p>Sign In to your account and start exploring !</p>
                    </div>
                    &lt;!&ndash;<div class="img__btn" (click)=toggle()>
                      <span class="m&#45;&#45;up">Welcome Center</span>
                      <span class="m&#45;&#45;in">Sign in</span>
                    </div>&ndash;&gt;
                    <div class="img__btn">
                      <span class="m&#45;&#45;up">Welcome</span>
                    </div>
                  </div>
                  &lt;!&ndash;<div>
                    <form class="theme-form text-left" [formGroup]="registrationForm">
                      <h4 class="text-center">Register Now</h4>
                      <h6 class="text-center">Enter your details and choose course</h6>
                      <div class="form-row mt-20px">
                        <div class="col-md-12">
                          <div class="form-group">
                            <input class="form-control border-radius-0" type="text" placeholder="Full Name"
                                   formControlName="name"/>
                            <small *ngIf="submitted && registrationForm.get('name').hasError('required')"
                                   class="text-danger">
                              Field is required.
                            </small>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <input class="form-control border-radius-0" type="text" placeholder="Email"
                                   formControlName="email"/>
                            <small *ngIf="submitted && registrationForm.get('email').hasError('required')"
                                   class="text-danger">
                              Field is required.
                            </small>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <input class="form-control border-radius-0" type="number" placeholder="Contact"
                                   formControlName="contact"/>
                            <small *ngIf="submitted && registrationForm.get('contact').hasError('required')"
                                   class="text-danger">
                              Field is required.
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <select class="form-control border-radius-0" formControlName="course_id">
                          <option value="">Select Course</option>
                          <option [value]="course.id" *ngFor="let course of courses">{{course?.name}}</option>
                        </select>
                        <small *ngIf="submitted && registrationForm.get('course_id').hasError('required')"
                               class="text-danger">
                          Field is required.
                        </small>
                      </div>
                      <div class="checkbox p-0 text-left">
                        <input id="checkbox2" type="checkbox" [(ngModel)]="agreeTermsCondtions"
                               [ngModelOptions]="{standalone: true}">
                        <label for="checkbox2" class="f_12">
                          <small>I agree to terms & conditions</small>
                        </label>
                      </div>
                      <div class="form-row">
                        <div class="col-sm-12">
                          <button class="btn btn-primary btn-block" type="submit" (click)="register()">Register</button>
                        </div>
                      </div>
                    </form>
                  </div>&ndash;&gt;
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>-->

<div class="container mx-auto" style="margin-top: 9rem;">
  <ng-template #content let-modal>
    <div id="modal">
      <div class="modal-header font-weight-bold">
        <h4 class="modal-title font-weight-bold" *ngIf="modalType === 'LOGIN_OTP'">Login OTP</h4>
        <h4 class="modal-title font-weight-bold" *ngIf="modalType === 'FORGOT_PASSWORD'">Forgot Password</h4>
        <h4 class="modal-title font-weight-bold" *ngIf="modalType === 'RESET_PASSWORD'">Reset Password</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="modalService.dismissAll('')"
        >
          ×
        </button>
      </div>
      <div class="modal-body" ngbAutoFocus>
        <div class="row" *ngIf="modalType === 'LOGIN_OTP'">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <form class="form" novalidate [formGroup]="centerLoginOtpForm">
                  <div class="row">
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                      <fieldset class="form-group">
                        <input class="form-control" type="number" formControlName="otp"
                               placeholder="Enter OTP">
                        <small *ngIf="submittedOtp && centerLoginOtpForm.get('otp').hasError('required')"
                               class="text-danger">
                          Field is required.
                        </small>
                      </fieldset>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-right">
                <small>Resend OTP in - </small>
                <small class="text-danger mr-1"
                       *ngIf="timerSixtySeconds > 0">{{timerSixtySeconds}} sec.</small>
                <small class="text-primary cursor-pointer"
                       *ngIf="timerSixtySeconds === 0" (click)="resendOTP()">Resend OTP ?
                </small>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="modalType === 'FORGOT_PASSWORD'">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <form class="form" novalidate [formGroup]="contactVerifyForm">
                  <div class="row">
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                      <fieldset class="form-group">
                        <label>Contact No.</label>
                        <input class="form-control" type="number" formControlName="contact"
                               placeholder="Enter Contact">
                        <small *ngIf="submittedContactVerify && contactVerifyForm.get('contact').hasError('required')"
                               class="text-danger">
                          Field is required.
                        </small>
                      </fieldset>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="modalType === 'RESET_PASSWORD'">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <form class="form" novalidate [formGroup]="updatePasswordForm">
                  <div class="row">
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                      <div class="form-group">
                        <label class="col-form-label">Contact</label>
                        <input class="form-control" formControlName="contact"/>
                        <small *ngIf="submitted && updatePasswordForm.get('contact').hasError('required')"
                               class="text-danger">
                          Field is required.
                        </small>
                      </div>
                    </div>
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                      <div class="form-group">
                        <label class="col-form-label d-flex">
                          Password
                          <i (click)="inputTypePassword = 'text'"
                             [ngClass]="{'display-hidden': (inputTypePassword === 'text')}"
                             class="fas fa-eye-slash d-flex align-items-center pl-1 cursor-pointer f_10"></i>
                          <i (click)="inputTypePassword = 'password'"
                             [ngClass]="{'display-hidden': (inputTypePassword === 'password')}"
                             class="fas fa-eye d-flex align-items-center pl-1  cursor-pointer f_10"></i>
                        </label>
                        <input class="form-control" [type]="inputTypePassword" formControlName="password"/>
                        <small *ngIf="submitted && updatePasswordForm.get('password').hasError('required')"
                               class="text-danger">
                          Field is required.
                        </small>
                      </div>
                    </div>
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                      <div class="form-group">
                        <label class="col-form-label d-flex">
                          Confirm Password
                          <i (click)="inputTypeConfirmPassword = 'text'"
                             [ngClass]="{'display-hidden': (inputTypeConfirmPassword === 'text')}"
                             class="fas fa-eye-slash d-flex align-items-center pl-1 cursor-pointer f_10"></i>
                          <i (click)="inputTypeConfirmPassword = 'password'"
                             [ngClass]="{'display-hidden': (inputTypeConfirmPassword === 'password')}"
                             class="fas fa-eye d-flex align-items-center pl-1  cursor-pointer f_10"></i>
                        </label>
                        <input class="form-control" [type]="inputTypeConfirmPassword" formControlName="confirm_password"/>
                        <small *ngIf="submitted && updatePasswordForm.get('confirm_password').hasError('required')"
                               class="text-danger">
                          Field is required.
                        </small>
                      </div>
                    </div>
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                      <div class="form-group">
                        <label class="col-form-label">Verification Code</label>
                        <input class="form-control" formControlName="otp" type="number"/>
                        <small *ngIf="submitted && updatePasswordForm.get('otp').hasError('required')"
                               class="text-danger">
                          Field is required.
                        </small>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-center">
            <button class="btn btn-primary btn-block btn-raised color-white mr-2 users-btn"
                    (click)="submitOtp()"
                    [disabled]="submittingOtp" *ngIf="modalType === 'LOGIN_OTP'">
              <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submittingOtp"></i>Submit
            </button>
            <button class="btn btn-primary btn-block btn-raised color-white mr-2 users-btn"
                    (click)="verifyContact()"
                    [disabled]="submittingOtp" *ngIf="modalType === 'FORGOT_PASSWORD'">
              <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submittingOtp"></i>Verify
            </button>
            <button class="btn btn-primary btn-block btn-raised color-white mr-2 users-btn"
                    (click)="resetPassword()"
                    [disabled]="submittingPassword" *ngIf="modalType === 'RESET_PASSWORD'">
              <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submittingPassword"></i>Reset
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <div class="card card0 border-0" style="border: none!important;">
    <div class="row d-flex">
      <div class="col-lg-6">
        <div class="card1 pb-5">
          <div class="row">
            <img src="assets/images/logo/logo.png" class="logo">
          </div>
          <div class="row px-3 justify-content-center mt-4 mb-5 border-line">
            <img src="https://i.imgur.com/uNGdWHi.png" class="image">
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <form class="theme-form" [formGroup]="loginForm">
          <div class="card2  px-4 py-5">
            <div class="row mb-4 px-3">
              <h1 class="mb-0 mr-4 mt-2">
                <strong>Sign In</strong><br>
                <p class="fs-5">Sign In to your account and start exploring</p>
              </h1>
              <br>
            </div>
            <div class="row px-3">
              <label class="mb-1"><h6 class="mb-0 text-sm">Username</h6></label>
              <input class="mb-4" type="text" placeholder="Volmint" formControlName="username"/>
            </div>
            <div class="row px-3">
              <label class="mb-1"><h6 class="mb-0 text-sm">Password</h6></label>
              <input type="password" placeholder="*******"
                     formControlName="password"/>
            </div>
            <div class="row px-3 mb-4 mt-4">
              <div class="custom-control custom-checkbox custom-control-inline">
                <input id="chk1" type="checkbox" name="chk" class="custom-control-input">
                <label for="chk1" class="custom-control-label text-sm">Remember me</label>
              </div>
              <a href="#" class="ml-auto mb-0 text-primary" (click)="openForgotPasswordModal(content)">Forgot Password?</a>
            </div>
            <div class="row mb-3 px-3">
              <button type="submit" class="bg-primary text-center w-100 py-2" style="border: none!important;"
                      (click)="signIn()">Login
              </button>

            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
